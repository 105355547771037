*, body {
 margin: 0px;
 padding: 0px;
}

body {
  padding: 1vh 1vw;
  font-size: 12px;
  background-color: #202830;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, span {
  color:#bbb
}
 td{
  padding-left: 10px !important;
}

span {
 font-size: .8rem;
}
.time-label {
  font-size: .6rem !important;
 }
 .row-r{
  margin-right: 0;
 }
 .padding-r{
  padding-right: 0;
 }
 .padding-l{
  padding-left: 0;
 }
 .padding-r5{
  padding-right: 5px;
 }
 .padding-l5{
  padding-left: 5px;
 }
 .sort-icon{
  height: 15px !important;
  cursor: pointer;
 }

.dw-options__control {
  border-radius: 0px !important;
}

input:required, textarea:required, .dw-dropdown-required>.dw-options__control, .dw-dropdown-required>.dw-options__control:hover
.dw-date-input-required {
 border-color: #f80;
}

.dw-date-input-required {
  border-color: #f80 !important;
}

#nav {
 width: 18rem;
}

.offcanvas {
  font-size: large;
  background-color: #27343d;
  color: #bbb;
  width: 20vw;
}

.offcanvas a, .dropdown-divider {
  color:#bbb;
}

.offcanvas a:hover {
  color:#fff;
}

.dropdown-menu {
  background-color: transparent;
  border-color: transparent;
}

.dropdown-item:hover {
  background-color: transparent;
}

.dropdown-divider {
  max-width: 75%;
  margin-left: .75vw;
}

.dropdown-toggle, .dropdown-toggle[aria-expanded="false"] {
 color:#bbb;
}

.dropdown-toggle[aria-expanded="true"] {
  color: white;
}


.navLinks>a {
  text-decoration: none;
  line-height: 2.75em;
}

.dw-btn, .dw-input, .dw-date-input {
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
  vertical-align: middle;
  display: inline-block;
  color: #bbb;
  padding: .375rem .75rem;
  margin-bottom: .5rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.dw-input, .dw-date-input {
  background-color: #27343d; 
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.react-datepicker__header {
  background-color: #27343d !important;
}

.react-datepicker__month {
  background-color: #345 !important;
  margin: 0.03rem !important;
}

.react-datepicker * {
  color: #bbb !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day--outside-month {
  color:#678 !important;
}
.MuiChip-deleteIcon{
  margin: 0px 0px 0 0px !important;
  padding-top: 4px;
  height: 20px !important;
}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 0;
  min-width: 0px !important;
}
.MuiChip-deleteIcon:hover{
  fill:#DE350B;
  background-color: #FFBDAD;
}
.MuiChip-root{
  background-color: hsl(0, 0%, 90%) !important;
  border-radius: 2px !important;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  margin: 2px !important;
  min-width: 0 !important;
  box-sizing: border-box !important;
  height: 21px !important;
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
}
.MuiChip-root span{  
  font-weight: bold;
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  padding-right: 6px;
}
.MuiChip-root svg{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 4px;
  box-sizing: border-box;
}
.MuiChip-root svg path{  
  d:path("M 14.348 14.849 c -0.469 0.469 -1.229 0.469 -1.697 0 l -2.651 -3.03 l -2.651 3.029 c -0.469 0.469 -1.229 0.469 -1.697 0 c -0.469 -0.469 -0.469 -1.229 0 -1.697 l 2.758 -3.15 l -2.759 -3.152 c -0.469 -0.469 -0.469 -1.228 0 -1.697 s 1.228 -0.469 1.697 0 l 2.652 3.031 l 2.651 -3.031 c 0.469 -0.469 1.228 -0.469 1.697 0 s 0.469 1.229 0 1.697 l -2.758 3.152 l 2.758 3.15 c 0.469 0.469 0.469 1.229 0 1.698 Z")
}
.MuiFilledInput-input{
  color: white !important;
}
.MuiAutocomplete-paper{
  background-color: #27343d !important;
  color: white !important;
}
.autocomplete{
  border-color: #bbb;
  border-width: 1px;
  border-style: solid;
  background-color: #27343d !important;
  height: 37px;
}
.MuiOutlinedInput-root{
  padding: 0 !important;
  color:#bbb !important;
}
.important{
  border-color: #f80 !important;
}
.react-datepicker__day:hover {
  background-color: #27343d !important;
}
.svg-center{
  transform: translate(0%,60%)!important;
}
.dw-btn {
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
}

.dw-input:focus, .dw-btn:focus, .dw-btn:active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.dw-options__input{
  color: white !important;
}
.Mui-focused{

}
.button120{
  width: 120px !important;
}

.dw-btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}
.css-319lph-ValueContainer{
  padding: 0 !important;
}
.dw-btn-primary {
  background-color: #000;
}

.dw-btn-primary:hover {
  background-color: #111;
}

.dw-btn-secondary {
  background-color: #456;
}

.dw-btn-secondary:hover {
  background-color: #678;
}

.dw-btn[aria-required="true"] {
  border: 1px solid #f80 !important;
}
.navbar-toggler-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}

.dw-options__control, .dw-options__menu-list {
  background-color: #27343d !important;
  color: #bbb;
}
/* .css-16awh2u-MuiAutocomplete-root{
  background-color: #27343d !important;
} */
.MuiFilledInput-root{
  padding-top: 0 !important;
}
.dw-options__single-value {
  color: #bbb !important;
}

.dw-options__control--is-focused {
  box-shadow: none !important;
  border-color: #ccc !important;
}

.dw-options__option--is-focused {
  background-color: #678 !important;
}

.dw-options__option--is-selected {
  /*
   color: #f80 !important;
  */
  background-color: #456 !important;
}
.width-100{
  width: 100% !important;
}
.dw-color-card{
  background-color: #35434E;
}
.dw-height-input{
  height: 66px;
  vertical-align: top !important;
  text-align: left !important;
}
.dw-height-100{
  height: 83px;
  vertical-align: top !important;
  text-align: left !important;
}
textarea { resize: none; }
.fa-input-icon {
 right: .75rem;
 top: .75rem;
 color: #bbb;
}
.fa-camera{
 color: #bbb;
 height: 25px;
}
.transparent{
  color: rgba(187, 187, 187, 0.487) !important;
}
.fa-windowR{
  color: #bbb;
  height: 20px;
 }
.no-padding{
  padding-left: 3px;
  padding-right: 0px;
}
.dw-setup-div{
  background-color: #1a202600;
  padding: 10px;
}
.dw-setup-div-click{
  background-color: #0a0e11;
  padding: 10px;
}
.dw-setup-div:hover{
  background-color: #1b2228;
  padding: 10px;
}
.dw-text-important{
  color:#f80 !important;
}
.image-table{
  padding: 6px !important;
}
.padding-center{
  padding-left: 15px !important;
}
.dw-note-table{
  margin-bottom: 0;
  width: 375px !important;
}
.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{
  margin-bottom: 0;
}
.text-right{
  text-align: right;
  cursor: pointer;
}

.dw-time-container {
  width: 14rem;
  display: inline-block;
  margin-right: .5rem;
}

.dw-options__indicator-separator {
  display: none !important;
}

.dw-checkbox {
  background: transparent;
}

.dw-modal-90w{
  max-width: 100% !important;
  margin: 0.5% !important;
}
.modal-content {
  background-color: #202830 !important;
}
.css-tlfecz-indicatorContainer{
  padding: 0px !important;
}
.css-1gtu0rj-indicatorContainer{
  padding: 0px !important;
}
.css-319lph-ValueContainer{
  padding: 1px 2px;
}
.red-card{
  background-color: #470F0E;
}
.time-color-blue{
  color: #5cf !important;
}
.x-scroller{
  overflow-x: hidden; 
  overflow-y:auto; 

}
.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.inLineScroll{
  display: inline-block;
}
.y-scroller{
  overflow-y: hidden; 
  overflow-x:auto; 

}
.tableFixHead          { overflow: auto; height: 100px; background-color: #345;}
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; background-color: #27343d;}

.delete-setup{
  float: right;
}

.tall-Comments{
  height: 150px;
}

.table>:not(caption)>*>* {
  padding: 0.5rem 0.1rem !important;
  padding-top: 11px !important;
}
.table-th{
 
}

.selected-tr{background-color: rgb(108, 166, 225);

}


.bg-dark{
  background-color: #27343d !important;
}
/* Just common table stuff. Really. */

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 1px solid rgb(0, 0, 0);
  background-clip: padding-box;
  background-color: #456;
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

@media (min-width: 992px) and (max-width: 1199px) { 
  .dw-height-100{
    height: 260px;
  }

 }



 .MuiBox-root {
  background-color: #35434E !important;
 }

 .blackBox{
  background-color: #1d1c1c !important;
}
::selection{
  color: rgb(41, 34, 75);
  background: #6990F2;
}
.wrapper{
  background: #202830 !important;
  border-radius: 5px;
  padding: 30px;
}
.wrapper header{
  color: #ffffff;
  font-size: 27px;
  font-weight: 600;
  text-align: center;
}
.wrapper form{
  height: 167px;
  display: flex;
  cursor: pointer;
  margin: 30px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px dashed #ffffff;
}
form :where(i, p){
  color: #ffffff;
}
form i{
  font-size: 50px;
}
form p{
  margin-top: 15px;
  font-size: 16px;
}

section .row{
  margin-bottom: 10px;
  background: #E9F0FF;
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section .row i{
  color: #6990F2;
  font-size: 30px;
}
section .details span{
  font-size: 14px;
}
.progress-area .row .content{
  width: 100%;
  margin-left: 15px;
}
.progress-area .details{
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  justify-content: space-between;
}
.progress-area .content .progress-bar{
  height: 6px;
  width: 100%;
  margin-bottom: 4px;
  background: #fff;
  border-radius: 30px;
}
.content .progress-bar .progress{
  height: 100%;
  width: 0%;
  background: #6990F2;
  border-radius: inherit;
}
.uploaded-area{
  max-height: 232px;
  overflow-y: scroll;
}
.uploaded-area.onprogress{
  max-height: 150px;
}
.uploaded-area::-webkit-scrollbar{
  width: 0px;
}
.uploaded-area .row .content{
  display: flex;
  align-items: center;
}
.uploaded-area .row .details{
  display: flex;
  margin-left: 15px;
  flex-direction: column;
}
.uploaded-area .row .details .size{
  color: #404040;
  font-size: 11px;
}
.uploaded-area i.fa-check{
  font-size: 16px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  color:white !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
  color:white !important;
}
.max-800{
  max-width: 800px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ishidden{
  display: none;
}

.col-project{
  width: 40%;
}
.col-episode{
  width: 20%;
}
.col-shot{
  width: 40%;
}
.col-void{
  width: 20%;
}
.col-scene{
  width: 40%;
}
.col-vfx{
  width: 40%;
}

.remove {
  padding: 2px;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 2px;
  border-radius: 3px;
}
.remove:hover {
  color: red;
  background: rgba(255,0,0,.1);
}
.MuiCheckbox-root{
  color:white !important
}

.MuiCollapse-wrapperInner{
  width: 90% !important;
  margin-left: auto !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
  
}
.MuiTableCell-root{
  border-bottom: none !important;
}

.input-group-custom{
  background: rgb(39 52 61);
  border: none;
  color: white;
}
.centerContent{
  display:flex;
  justify-content: center;
  align-items: center;
}
.specialSpaceTH{
  width: 160px;
}
.eyeOrange{
  border-top: 1px solid #f80;
  border-right: 1px solid #f80;
  border-bottom: 1px solid #f80;
}
.divider {
  flex-grow: 1;
  border-bottom: 1px solid gray;
  margin: 5px
}
.MuiChip-root span{
font-size: 65% !important;
padding-left: 2px !important;
padding-right: 2px !important;
}
.css-16awh2u-MuiAutocomplete-root .MuiFilledInput-root{
  padding-left: 5px !important;
}
.css-11v1qwh-MuiInputBase-root-MuiFilledInput-root:after{
  border-bottom: none !important;
}
.MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiFilledInput-root{
  overflow-y: scroll !important;
  max-height: 37px !important;
}
.bp-blue{
  color: #5cf !important;
  text-align: right !important;
}
.bp-center{
  text-align: center !important;
}

.bp-background{
  background-color: rgb(32 40 48) !important;
  width: 100% !important;
}
.orangeLink{
  color: #f80;
}
.orangeLink:hover{
  color: rgb(182, 100, 6);
}
.bp-p{
  color: #bbb;
}
/* .headerTable th span{
  width: max-content
} */
.iconColor{
  color: rgb(151, 147, 147) !important;
}
.listItem{
  background-color: #2e3d4c !important;
}
.bp-width-20{
  width: "20px" !important;
  /* display: flex;
  justify-content: space-around;
  align-items: flex-end; */
}
.dw-date-input{
  font-size: 0.7rem;
  height: 38px;
}

.ratio{
  aspect-ratio: 16/9;
  object-fit: contain;
  cursor: pointer;
}
.ratio_noCursor{
  aspect-ratio: 16/9;
  object-fit: contain;
}
.ItemList{
  display: flex;
  align-items: center;
  background-color: #2e3d4c !important;
  color: rgb(151, 147, 147) !important;
  height: 50px;
  margin: 5px;
  padding-left: 20px;
  padding-right: 15px;
  justify-content: space-between;
}
.ItemP{
  display: contents;
  text-align: center;
  font-size: large;
  font-weight: bold;
}
.itemButton{
  color: red;
  background-color: transparent;
  border: none;
}
.itemEditButton{
  color: gray;
  background-color: transparent;
  border: none;
  margin-right: 1rem;
}
.ItemUl{
  padding-left: 0;
}
.SortableList{
  height: 50vh;
  overflow-y: scroll;
}
.disableLink{
  color: grey !important;
  cursor: default;
}
.swal2-popup span{
  font-size: 1rem !important;
}
.customClass{
  display: flex;
}